import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import translate from "../utils/translation"
import styles from "./donation.module.scss"


class DonationTemplate extends React.Component {

  render() {
    const post = this.props.data.markdownRemark
    const lang = post.fields.slug.split('/')[1]
    const { parent, altPost } = this.props.pageContext
    const siteTitle = translate('site.name', lang)
    const headerSubTitle = parent ? parent.frontmatter.title : translate('site.description', lang)
    const backPath = parent ? parent.fields.slug : `/${lang}/`

    return (
      <Layout location={this.props.location} title={siteTitle} subTitle={headerSubTitle} slug={post.fields.slug} hasLanguage={!!altPost}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          lang={lang}
          slug={post.fields.slug}
        />
        <article className="main-content">
          <div className={styles.container}>
            <h1 className={styles.title}>{post.frontmatter.title}</h1>
            <p className={styles.description}>{post.frontmatter.description}</p>
            <h3 className={styles.credit}>{post.frontmatter.credit}</h3>
            <div className={styles.control}>
              <a rel="go-donation"
                href='https://syncable.biz/associate/Butoh-Kaden/'
                target='_blank'
                style={{
                  boxShadow: `none`,
                  textDecoration: `none`,
                  color: `inherit`,
                }}>
                <button>{translate('donation.go', lang)}</button>
              </a>
            </div>
            <div dangerouslySetInnerHTML={{ __html: post.html }} className={styles.text}></div>
            <div className={styles.control}>
              <a rel="go-donation"
                href='https://syncable.biz/associate/Butoh-Kaden/'
                target='_blank'
                style={{
                  boxShadow: `none`,
                  textDecoration: `none`,
                  color: `inherit`,
                }}>
                <button>{translate('donation.go', lang)}</button>
              </a>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export default DonationTemplate

export const pageQuery = graphql`
  query DonationBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        images
        credit
      }
    }
  }
`
